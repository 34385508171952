export const environment = {
  production: false,
  host            : 'https://stag-social-api.aeralife.com',
  prefix          : '/api',
  webstorage_name : 'aeralife_staging',
  app : {
    name : 'AeraLife Social',
    domain : 'https://stag-social.aeralife.com',
  },
};
