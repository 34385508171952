import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {LogoutComponent} from './pages/LogoutComponent';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ApiModule} from './_api/api.module';
import {environment} from '../environments/environment';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AmazonLoginProvider,
} from 'angularx-social-login';
import {ToastrModule} from 'ngx-toastr';
import {ModalModule} from 'ngx-bootstrap/modal';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {RegisterModalComponent} from './modal/register/register.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PostCreateModalComponent } from './pages/modal/post-create/post-create-modal.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { PostEditModalComponent } from './pages/modal/post-edit/post-edit-modal.component';
import {NgxFileUploaderModule} from '@uniprank/ngx-file-uploader';
import { FeedModalDataComponent } from './pages/modal/post-data-view/feed-modal-data.component';
import {AuthService} from './_auth/auth.service';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ProfilePhotoUploadComponent} from './pages/modal/profile-photo-upload/profile-photo-upload.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ImageCropperModule} from 'ngx-image-cropper';
import {UserService} from './_service/user.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PhotoViewModalComponent} from './pages/modal/photo-view/photo-view-modal.component';
import {ProfileCoverSelectModalComponent} from './pages/modal/profile-cover-select/profile-cover-select-modal.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {PostPrivacyModalComponent} from './pages/modal/post-privacy/post-privacy-modal.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
// }
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const MAT_MODULES = [
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatSelectModule,
];

@NgModule({
  declarations: [
    AppComponent,
    RegisterModalComponent,
    PostCreateModalComponent,
    PostEditModalComponent,
    FeedModalDataComponent,
    ProfilePhotoUploadComponent,
    PhotoViewModalComponent,
    ProfileCoverSelectModalComponent,
    PostPrivacyModalComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxPageScrollCoreModule.forRoot({duration: 600}),
    NgxPageScrollModule,
    NgxWebstorageModule.forRoot({prefix: environment.webstorage_name, caseSensitive: true}),
    ApiModule.forRoot({
      apiConfig: {
        host: environment.host,
        prefix: environment.prefix
      }
    }),
    SocialLoginModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    LoadingBarRouterModule,
    LoadingBarModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxFileUploaderModule.forRoot(),
    BsDropdownModule.forRoot(),
    ImageCropperModule,
    ...MAT_MODULES,
    AlertModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              'clientId'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId'),
          },
          {
            id: AmazonLoginProvider.PROVIDER_ID,
            provider: new AmazonLoginProvider(
              'clientId'
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    AuthService,
    UserService,
  ],
  entryComponents: [
    RegisterModalComponent,
    PostCreateModalComponent,
    PostEditModalComponent,
    FeedModalDataComponent,
    ProfilePhotoUploadComponent,
    PhotoViewModalComponent,
    ProfileCoverSelectModalComponent,
    PostPrivacyModalComponent,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }

// platformBrowserDynamic().bootstrapModule(AppModule);
