import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {FileFilter, FileUploader, HookTypeEnum, UploaderHook} from '@uniprank/ngx-file-uploader';

@Component({
  selector: 'app-post-edit',
  templateUrl: './post-edit-modal.component.html',
  styleUrls: ['./post-edit-modal.component.scss']
})
export class PostEditModalComponent implements OnInit {

  @Input() public user_data: any;
  @Input() public post: any = {};
  @ViewChild('inputfile') inputfile: ElementRef;

  public onClose: Subject<boolean>;
  remove_data_ids = [];
  post_data: any;
  post_data_update: any = {
    id: '',
    description: '',
    status_privacy: 'public',
  };

  public uploader: FileUploader;
  private _subs: Subscription;
  private files: any = [];

  constructor(
    public bsModalRef: BsModalRef,
  ) {

    this.uploader = new FileUploader({
      // url: 'https://api.uniprank.com/imageupload/api/upload',
      removeBySuccess: false,
      autoUpload: false,
      filters: [
        new FileFilter('only:JPG/PNG/GIF', new RegExp('image/jpeg|image/png|image/gif'), 'type')
      ],
    });

    // this.hookName = HookTypeEnum[HookTypeEnum.successUploadFile];

    this.uploader.hook(
      new UploaderHook(
        HookTypeEnum.successUploadFile,
        (response: any, status: any, headers: any) => {
          console.log('Hook', HookTypeEnum.successUploadFile, response, status, headers);
        },
        0
      )
    );

    this._subs = this.uploader.queue$.subscribe(
      async (data: any) => {

        if( data.length > 0) {

          this.files = [];

          for (var i = 0; i < data.length; i++) {

            let file = data[i]._fileElement;

            if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
              this.files.push({
                file: file,
                description: '',
              });
            }
          }
          // this.inputfile.nativeElement.value = '';
        }
      },
      (error: any) => {
        throw new Error(error);
      }
    );
  }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.post_data = this.post.datas[0];
    this.post_data_update = {
      id: this.post_data.id,
      description: this.post_data.description,
      status_privacy: this.post_data.status_privacy,
    };

  }

  listOnlyImage(){
    if( this.post && this.post.datas && this.post.datas.length>0 ){
      return this.post.datas.filter((item) => item.media_type === 'photo');
    }else{
      return [];
    }
  }

  postUpdate( form: NgForm ) {
    if (form.valid) {
      this.post_data_update.remove_ids = this.remove_data_ids;
      this.onConfirm( { data_update: this.post_data_update, new_datas: this.files} );
    }
  }

  public onConfirm(data): void {
    this.onClose.next(data);
    // this.bsModalRef.hide();
  }

  public onError(): void {
    this.onClose.next(false);
  }

  removeData(item){
    var index = this.post.datas.indexOf(item);
    this.post.datas.splice(index, 1);
    this.remove_data_ids.push(item.id);
  }
}
