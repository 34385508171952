import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Api} from '../_api/api';

@Injectable({
  providedIn: 'root'
})
export class PostDataService {

  constructor(
    private http: HttpClient ,
    private api: Api,
  ) { }


  list(post_id: number){
    return this.api.request('/post/data', 'GET',{ fid: post_id });
  }

  get(id: number){
    return this.api.request('/post/data/' + id, 'GET');
  }

  changeFeelType(post){
    return this.api.request('/post/data/feel_type/' + post.id, 'post',{}, post.my_feel);
  }

  createComment(post_data: any,comment: string) {

    let data = {
      post_data_id: post_data.id,
      comment: comment,
    }
    return this.api.request('/post/data/comment', 'POST', {} , data);
  }

  // update(item: any,post_update: any , datas: any) {
  //
  //   const formData: FormData = new FormData();
  //
  //   formData.append('_method', 'PUT');
  //   for (var key in post_update) {
  //     if (post_update.hasOwnProperty(key) ) {
  //       if(post_update[key]!==null){
  //         if( key=='remove_data_ids' ){
  //           formData.append(key, JSON.stringify(post_update[key]));
  //         }else{
  //           formData.append(key,post_update[key]);
  //         }
  //       }else{
  //         formData.append(key,'');
  //       }
  //     }
  //   }

  updateComment(item: any) {

    let data = {
      comment_update: item.comment_update,
    }

    return this.api.request('/post/data/comment/' + item.id, 'PUT', {} , data);
  }
  //
  // delete( id: number){
  //   return this.api.request('/post/' + id, 'DELETE');
  // }
  //
  deleteComment( id: number){
    return this.api.request('/post/data/comment/' + id, 'DELETE');
  }

  changeCommentFeelType(post_comment){
    return this.api.request('/post/data/comment/feel_type/' + post_comment.id, 'post',{}, post_comment.my_feel);
  }

  createCommentReply(comment: any, comment_reply: string) {

    let data = {
      comment_id: comment.id,
      comment_reply: comment_reply,
    }
    return this.api.request('/post/data/commt_rep', 'POST', {} , data);
  }

  delete( id: number){
    return this.api.request('/post/data/' + id, 'DELETE');
  }

  updatePrivacy(item_data: any) {

    // let data = {
    //   comment_update: item_data.comment_update,
    // }
    return this.api.request('/post/data/privacy/' + item_data.id, 'PATCH', {} , item_data);
  }
}
