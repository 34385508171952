import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Api } from '../_api/api';
import { User } from '../_model/user';

@Injectable()
export class UserService {

    constructor(private http: HttpClient ,
                private api: Api ) { }

    getAll() {
        // return this.http.get<User[]>('/api/users');
        return this.api.request('/admin/user', 'GET');
    }

    listPost(data) {
      return this.api.request('/admin/user/list', 'POST', {} , data);
    }

    getProfile() {
        return this.api.request('/profile', 'GET');
    }

    getByUsername(username) {
      return this.api.request('/user/'+username, 'GET');
    }

    edit(id: number) {
        return this.api.request('/admin/user/'+ id +'/edit', 'GET');
    }

    create(user: User) {
        return this.api.request('/admin/user', 'POST', {} , user);
    }

    update(user: User) {
        user.id = 1;
        return this.api.request('/user/0' , 'PUT',{} , user);
        // return this.http.put('/api/users/' + user.id, user);
    }

    uploadProfilePhoto(file_image: File = null, description: string='') {
        // console.log('uploadProfilePhoto',uploader);
        // return this.api.uploadFiles('/admin/user/upload/'+id , 'POST',uploader);
        // return this.http.put('/api/users/' + user.id, user);
      // console.log('file_image',file_image)
      const formData: FormData = new FormData();
      if( file_image && file_image.name)
        formData.append('file_photo', file_image, file_image.name);
      formData.append('description', description);

      return this.api.requestFile('/user/upload' , 'POST',formData);
    }

    delete(id: number) {
        return this.api.request('/user/' + id, 'DELETE');
    }

    getProfilePhotos(){
      return this.api.request('/profile/photos', 'GET');
    }

    updateProfilePhoto(data){
      return this.api.request('/profile/photo/'+data.photo_id, 'PATCH',{}, data);
    }

    getCoverPhotos(){
      return this.api.request('/profile/covers', 'GET');
    }

  uploadProfileCoverPhoto(file_image: File = null, image_cover_offset: any = {}, description: string = '') {
    // console.log('uploadProfilePhoto',uploader);
    // return this.api.uploadFiles('/admin/user/upload/'+id , 'POST',uploader);
    // return this.http.put('/api/users/' + user.id, user);
    // console.log('file_image',file_image)
    const formData: FormData = new FormData();
    if( file_image && file_image.name)
      formData.append('file_photo', file_image, file_image.name);
    formData.append('description', description);
    formData.append('image_cover_offset', JSON.stringify(image_cover_offset) );

    return this.api.requestFile('/profile/cover/upload' , 'POST',formData);
  }

  updateProfileCoverPhoto(image_cover_selected: any){
    return this.api.request('/profile/cover/update/'+image_cover_selected.id , 'PATCH',{} , image_cover_selected);
  }

  removePhotoCover(user_cover_id: number) {
    return this.api.request('/profile/cover/remove/'+user_cover_id, 'DELETE');
  }

  addFriend(user_id: number, status: string) {

    let data = {
      relation_id: user_id,
      status: status,
    };

    return this.api.request('/user/relation/change', 'POST', {}, data);
  }

  confirmFriend(user_id: number, status: string) {

    let data = {
      relation_id: user_id,
      status: status,
    };

    return this.api.request('/user/relation/change', 'POST', {}, data);
  }
}
