import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    attribute;

    constructor(
      private router: Router ,
      private storage: LocalStorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      this.attribute = this.storage.retrieve('login_data');
      if ( this.attribute ){
          return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth','login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
}
