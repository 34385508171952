import {Injectable} from '@angular/core';
import {Api} from '../_api/api';
import {LocalStorageService} from 'ngx-webstorage';

import {Regist} from '../_model/regist';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthService {

    private admin_instance: any[];
    public is_logged: boolean = false;

    public onAuthHandler: any;
    public onLogoutHandler: any;

    login_data: any = {};
    setting_data: any = {};

    constructor(
      private api: Api ,
      private storage: LocalStorageService,
      private router: Router,
      public translate: TranslateService,
    ) {

      // this.check_login().subscribe(
      //   (response: any): void => {
      //     // console.log('init response', response);
      //     if (response && response.body.member) {
      //       console.log('user');
      //       this.profile();
      //     } else {
      //       console.log('user');
      //       this.router.navigate(['/logout']);
      //     }
      //   }, error => {
      //     console.log('user');
      //     this.router.navigate(['/logout']);
      //   }
      // );
      this.profile();
    }

    getUserSetting(){
      if (!this.storage.retrieve('setting')) {
        this.storage.store('setting', {} );
      }
      this.setting_data = this.storage.retrieve('setting');

      if( !this.setting_data.language ){
        this.api.request('/user/setting/general', 'GET')
          .subscribe(
            (resp: any) => {
              if (resp.body && resp.body.success) {

                // this.setting_data = Object.assign({}, resp.body.user_setting);
                this.setting_data = resp.body.user_setting;
                if( this.setting_data.language ) {
                  this.translate.use(this.setting_data.language);
                }else{
                  const browserLang = this.translate.getBrowserLang();
                  this.translate.use(browserLang.match(/en|th/) ? browserLang : 'en');
                  this.setting_data.language = this.translate.currentLang;
                }

                this.storage.store('setting', this.setting_data );
              }
            },
            error => {
              console.error('error', error.errors);
            });
      }else{
        this.translate.use(this.setting_data.language);
      }

    }

    // public login_old(email_or_mobile?: string, password?: string): Promise<Object> {
    //
    //     let promise: Promise<Object>;
    //     promise = new Promise<Object>((resolve, reject): void => {
    //         let token: string;
    //         let data: any;
    //         token = this.storage.retrieve('login_token');
    //         data = this.storage.retrieve('login_data');
    //         console.log('_auth init',token,data);
    //         if (token && data) {
    //             // this.logged(token,data);
    //             this.login_data = JSON.parse(data);
    //             this.api.setAuthorization(token);
    //
    //             resolve(token);
    //         } else {
    //             reject();
    //         }
    //     });
    //
    //     return promise;
    // }
    //

    // getCurrentLoggedIn() {
    //     this.afAuth.authState.subscribe(_auth => {
    //         if (_auth) {
    //             this.router.navigate(['/'])
    //         }
    //     });
    // }

    // public check(): Promise<Regist> {
    //     let promise: Promise<Regist>;
    //     promise  = new Promise<Regist>((resolve, reject): void => {
    //         this._api.request('admin/data', 'POST', {}, {
    //             //
    //         }).subscribe((response: any): void => {
    //             if (response && response.body.data) {
    //                 this.login_data.reset();
    //                 this.login_data.clone(response.body.data);
    //             }
    //         }, error => {
    //             if (error) {
    //                 //this.Regist.error(error);
    //                 console.error(error);
    //             }
    //             reject(error);
    //         });
    //     });
    //
    //     return promise;
    // }

    public login_old(email_or_mobile?: string, password?: string): Promise<Object> {

        let promise: Promise<Object>;
        promise  = new Promise<Object>((resolve, reject): void => {
            // this.admin.process('login');
            this.api.request('/login', 'POST', {}, {
                email_or_mobile    : (email_or_mobile) ? email_or_mobile : '',//this.admin.username,
                password    : (password) ? password : '',//this.admin.password
            }).subscribe((response: any): void => {
                if (response && response.body === true) {
                    // this.admin.reset();
                    // this.admin.clone(response.body.data);
                    this.logged(response.body.token,response.body.data);
                    // localStorage.setItem('login_token', response.body.token);
                    resolve(true);
                } else {
                    resolve(false);
                }
            }, error => {
                if (error) {
                    //this.Regist.error(error);
                    console.error(error);
                }
                reject(error);
            });
        });

        return promise;
    }

  public login(email_or_mobile?: string, password?: string) {


    return this.api.request('/login', 'POST', {}, {
        email_or_mobile    : email_or_mobile,//this.admin.username,
        password : password,//this.admin.password
      });
  }

  public profile(force_update: boolean = false) {

    if( force_update ){
      console.log('force update_data_storage');
      this.update_data_storage();
    }
    else{
        if ( this.storage.retrieve('login_data') ) {
          this.login_data = this.storage.retrieve('login_data');
          if ( this.login_data.firstname ) {
            this.is_logged = true;
          }
        } else{
          this.update_data_storage();
        }
    }

    return this.login_data;
  }

  public checkLogin(){
      return this.api.request('/profile', 'GET');
  }

  public updateLoginData(login_data){
    this.storage.store('login_data', login_data);
  }

  updataProfile(user){
    let login_data = {
      id: user.id,
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      photo_url: user.photo_url,
      photo_thumb_url: user.photo_thumb_url,
    };

    this.storage.store('login_data', login_data);
    return login_data;
  }

  public update_data_storage(member: any = null): void {
    console.log('== call api update member ==');
    if( member ) {
      this.storage.store('login_data', member );
    }else{
      this.api.request('/profile', 'GET')
        .subscribe((response: any): void => {
          // console.log('init response', response);
          if (response && response.body) {
            let login_data = {
              id: response.body.user.id,
              username: response.body.user.username,
              firstname: response.body.user.firstname,
              lastname: response.body.user.lastname,
              photo_url: response.body.user.photo_url,
              user_photo_id: response.user_photo_id,
              photo_thumb_url: response.body.user.photo_thumb_url,
              photo_cover_id: response.body.user.photo_cover_id,
              photo_cover_url: response.body.user.photo_cover_url,
              photo_cover_offset: response.body.user.photo_cover_offset,
            };
            this.storage.store('login_data', login_data);
            this.is_logged = true;
            this.login_data = login_data;
            // return response.body.member;
          } else {
            // console.error('load profile', response);
            // this.router.navigate(['./logout']);
            // this.logout();
          }
        }, error => {
          console.error('load profile', error);
          // this.logout();
          this.router.navigate(['./logout']);
        });
    }
    // this.storage.store('login_data', member);
  }

    public logout() {
    //     let promise: Promise<RegistModel>;
    //     promise = new Promise<RegistModel>((resolve, reject): void => {
    //         // this.admin.process('logout');
    //         this._api.request('admins/logout', 'POST', {} ,{
    //             //
    //         }).subscribe((response: any): void => {
    //             this.admin.reset();
    //             this.admin.clear();
    //
    //             this.storage.clear('token');
    //             this._api.setAuthorization('');
    //             this.is_logged = false;
    //
    //             resolve(this.admin);
    //         }, error => {
    //             this.admin.error(error);
    //             reject(this.admin);
    //         });
    //     });
    //
    //     return promise
        // this.router.navigate(['/auth','login']);

        // localStorage.removeItem('login_token');
        return this.api.request('/logout', 'GET');
    }
    //
    // public profile(): Promise<RegistModel> {
    //     let promise: Promise<RegistModel>;
    //     promise  = new Promise<RegistModel>((resolve, reject): void => {
    //         this._api.request('admins/profile', 'POST', {}, {
    //             //
    //         }).subscribe((response: any): void => {
    //             if (response && response.body.data) {
    //                 this.admin.reset();
    //                 this.admin.clone(response.body.data);
    //             }
    //             resolve(this.admin);
    //         }, error => {
    //             this.admin.error(error);
    //             reject(this.admin);
    //         });
    //     });
    //
    //     return promise;
    // }
    //
    public logged(token: string,user: any): void {
        console.log('== LOGGED ==');
        let login_data = {
          id: user.id,
          username: user.username,
          firstname: user.firstname,
          lastname: user.lastname,
          photo_url: user.photo_url,
          user_photo_id: user.user_photo_id,
          photo_thumb_url: user.photo_thumb_url,
        }

        this.is_logged = true;
        this.storage.store('login_data', login_data);
        this.storage.store('login_token', token);
        // this.api.setAuthorization(token);
    }

  public updatelocalProfilePhoto(data){
    this.login_data.user_photo_id = data.photo_id;
    this.login_data.photo_url = data.photo_url;
    this.login_data.photo_thumb_url = data.photo_thumb_url;
    this.storage.store('login_data', this.login_data);
  }

  public updatelocalProfilePhotoCover(data){
    this.login_data.photo_cover_id = data.photo_cover_id;
    this.login_data.photo_cover_url = data.photo_cover_url;
    this.login_data.photo_cover_offset = data.photo_cover_offset;
    this.storage.store('login_data', this.login_data);
  }

    // public get admin(): any {
    //     if (!this.login_data) {
    //         this.login_data = this.storage.retrieve('regist_data');
    //     }
    //     return this.login_data;
    // }

    // public get isLogged(): boolean {
    //     return this.is_logged;
    // }

  register(regist: Regist) {
    return this.api.request('/user', 'POST', {} , regist);
  }

  public socialLogin(platform?: string, registData?: any): Promise<Object> {

    let promise: Promise<Object>;
    promise  = new Promise<Object>((resolve, reject): void => {

      this.api.request('/sociallogin', 'POST', {}, {
        platform    : platform,
        registData    : registData,
      }).subscribe((response: any): void => {
        if (response && response.body === true) {
          this.logged(response.body.token,response.body.data);
          resolve(response);
        } else {
          resolve(response);
        }
      }, error => {
        console.error(error);
        resolve(error);
      });
    });

    return promise;
  }

}
