import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../../_service/user.service';
import {PostService} from '../../../_service/post.service';

@Component({
  selector: 'app-profile-cover-select',
  templateUrl: './profile-cover-select-modal.component.html',
  styleUrls: ['./profile-cover-select-modal.component.scss']
})
export class ProfileCoverSelectModalComponent implements OnInit {

  public onClose: Subject<any>;
  list_photos_loading: boolean = false;
  cover_photos: any = [];
  photo_data_update: any = {};

  constructor(
    private _formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private userService: UserService ,
    private post_service: PostService,
  ) {
    this.onClose = new Subject();
    this.getUserPhotos();
  }

  ngOnInit(): void {
  }

  getUserPhotos(){

    this.list_photos_loading = true;
    this.userService.getCoverPhotos()
      .subscribe((response: any): void => {
        if (response && response.body) {
          this.cover_photos = response.body.user_photos;
          this.list_photos_loading = false;
        }
      }, error => {
        this.list_photos_loading = false;
        console.error('load profile', error);
      });
  }

  selectPhoto(item){

    let data = {
      type: 'selected',
      id: item.id,
      photo_cover_url: item.photo_cover_url,
    };

    if( this.photo_data_update.id == item.id ){
      data.type = 'unselect';
    }

    this.photo_data_update = data;
  }

  updatePhotoAndClose(){
    this.onClose.next( this.photo_data_update );
    this.bsModalRef.hide();
  }
}
