import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';
import {Subject} from 'rxjs';
import {UserService} from '../../../_service/user.service';
import {toBase64String} from '@angular/compiler/src/output/source_map';
import {PagesComponent} from '../../pages.component';
import {HttpEventType} from '@angular/common/http';
import {PostService} from '../../../_service/post.service';

@Component({
  selector: 'app-profile-photo-upload',
  templateUrl: './profile-photo-upload.component.html',
  styleUrls: ['./profile-photo-upload.component.scss']
})
export class ProfilePhotoUploadComponent implements OnInit {

  // user_data = {};
  // @Input() public user_data: any;
  // @ViewChild('inputfile') inputfile: ElementRef;
  public onClose: Subject<any>;
  profile_photos: any = [];
  list_photos_loading: boolean = false;
  imageUrlToCrop = null;
  photo_data_update: any = {};

  profile_photo_url = '';
  profile_photo_file: any;
  profile_photo_description: '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  profile_photo_uploading: boolean = false;

  container_crop_show = false;

  post: any = {
    content: '',
    privacy: 'public',
  }

  constructor(
    private _formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private userService: UserService ,
    private post_service: PostService,
  ) {
    this.onClose = new Subject();
    this.getUserPhotos();
  }

  ngOnInit(): void {
  }

  getUserPhotos(){

    this.list_photos_loading = true;
    this.userService.getProfilePhotos()
      .subscribe((response: any): void => {
        if (response && response.body) {
          this.profile_photos = response.body.user_photos;
          this.list_photos_loading = false;
        }
      }, error => {
        this.list_photos_loading = false;
        console.error('load profile', error);
      });
  }

  selectPhoto(item){

    let data = {
      type: 'select',
      photo_id: item.id,
      photo_thumb_url: item.photo_thumb_url,
    };

    this.photo_data_update = data;
  }

  updatePhotoAndClose(data){
    this.onClose.next( data );
    this.bsModalRef.hide();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.profile_photo_url = event.target.files.item(0);
    this.container_crop_show = true;
  }
  cropImageCancel(){
    this.container_crop_show = false;
    this.imageChangedEvent = '';
    this.profile_photo_url = '';
    this.profile_photo_file = null;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.profile_photo_file = base64ToFile(this.croppedImage);
    // this.photo_file = this.DataURIToBlob(this.croppedImage);
    this.profile_photo_file.name = 'image.jpg';
    // this.createBlobImageFileAndShow();
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  uploadFileAndClose() {

    this.profile_photo_uploading = true;
    this.userService.uploadProfilePhoto( this.profile_photo_file )
      .subscribe(
        // .subscribe(
        (resp: any) => {
          if (resp && resp.body ) {
            if( resp.body.success ){
              let data = {
                type: 'select',
                photo_id: resp.body.id,
                photo_thumb_url: resp.body.photo_thumb_url,
              };

              this.updatePhotoAndClose(data);
            }else{
              console.error('uploaded error',resp );
            }
            this.profile_photo_uploading = false;
          }
        },
        error => {
          this.profile_photo_uploading = false;
          console.error('error',error );
          // this.base.showToast( 'error',error.message);
        }
      );
  }

  /*createPost(){

    this.profile_photo_uploading = true;

    var file = [{
      file: this.profile_photo_file,
      media_group: 'profile',
      description: this.profile_photo_description,
    }];

    this.post_service.createUpload(this.post, file)
      .subscribe(
        (resp: any) => {

          if( resp && resp.body ) {
            if (resp.body.success) {
              // resp.body.post.data_images = this.setDataImage(resp.body.post.datas);
              let data = {
                type: 'select',
                photo_id: resp.body.post.datas[0].id,
                photo_thumb_url: resp.body.post.datas[0].photo_thumb_url,
              };

              this.updatePhotoAndClose(data);
            } else {
              console.error('error', resp.body.errors);
            }
          }
        },
        error => {
          // this.loading = false;
          console.error('error', error.errors);
        });
  }*/

  public modalClose(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }

}
