import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Api} from '../_api/api';
import {Post} from '../_model/post';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(
    private http: HttpClient ,
    private api: Api,
  ) { }

  create(post: Post ) {
    return this.api.request('/post', 'POST', {} , post);
  }

  createUpload(post: Post  , datas: any) {

    const formData: FormData = new FormData();

    for (var key in post) {
      if (post.hasOwnProperty(key) ) {
        if(post[key]!==null){
          formData.append(key,post[key]);
        }else{
          formData.append(key,'');
        }
      }
    }

    for (var i = 0; i < datas.length; i++) {
      // formData.append("datas[]", datas[i], datas[i].name);
      formData.append("datas[][file]", datas[i].file );
      formData.append("datas[][description]", datas[i].description );
    }

    return this.api.requestFile('/post', 'POST', formData);
  }

  changeFeelType(post){
    return this.api.request('/post/feel_type/' + post.id, 'post',{}, post.my_feel);
  }

  createComment(post: any,comment: string) {

    let data = {
      post_id: post.id,
      comment: comment,
    }
    return this.api.request('/post/comment', 'POST', {} , data);
  }

  createCommentReply(comment: any, comment_reply: string) {

    let data = {
      comment_id: comment.id,
      comment_reply: comment_reply,
    }
    return this.api.request('/post/commt_rep', 'POST', {} , data);
  }


  changeCommentFeelType(post_comment){
    return this.api.request('/post/comment/feel_type/' + post_comment.id, 'post',{}, post_comment.my_feel);
  }

  get(id: Number ) {
    return this.api.request('/post/' + id, 'GET');
  }

  edit(id: Number ) {
    return this.api.request('/post/' + id + '/edit', 'GET');
  }

  update(post: any,post_update: any , new_datas: any) {

    const formData: FormData = new FormData();

    formData.append('_method', 'PUT');
    for (var key in post_update) {
      if (post_update.hasOwnProperty(key) ) {
        if(post_update[key]!==null){
          if( key=='remove_ids' ){
            formData.append(key, JSON.stringify(post_update[key]));
          }
          else{
            formData.append(key,post_update[key]);
          }
        }else{
          formData.append(key,'');
        }
      }
    }

    for (var i = 0; i < new_datas.length; i++) {
      // console.log('json',new_datas[i],new_datas[i].file,JSON.stringify(new_datas[i].file));
      formData.append("new_datas[][file]", new_datas[i].file );
      formData.append("new_datas[][description]", new_datas[i].description );
      // formData.append("new_datas["+i+"][description]", new_datas[i].description);
      // formData.append("new_datas[]", {
      //   file: new_datas[i].file,
      //   description: new_datas[i].description,
      // });
    }

    // return this.api.request('/post/' + item.id, 'PUT', {} , post_update);
    return this.api.requestFile('/post/' + post.id, 'POST', formData);
  }

  updateComment(item: any) {

    let data = {
      comment_update: item.comment_update,
    }

    return this.api.request('/post/comment/' + item.id, 'PUT', {} , data);
  }

  delete( id: number){
    return this.api.request('/post/' + id, 'DELETE');
  }

  deleteComment( id: number){
    return this.api.request('/post/comment/' + id, 'DELETE');
  }

  listImage(post_id: number){
    return this.api.request('/post/data', 'GET',{ fid: post_id });
  }

  getImage(id: number){
    return this.api.request('/post/data/' + id, 'GET');
  }

  updateDescription(post_data: any) {

    let data = {
      description_update: post_data.description_update,
    }

    return this.api.request('/post/data/dept/' + post_data.id, 'PATCH', {} , data);
  }
}
