import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {LogoutComponent} from './pages/LogoutComponent';
import {AuthGuard} from './_auth/auth.guard';

const routes: Routes = [

  {
    path : 'auth',
    loadChildren: () => import('./_auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'page',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [AuthGuard],
  },

  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  // imports: [RouterModule.forRoot(routes, config)],
  // exports: [RouterModule],
  // providers: [
  //   // // { provide: LocationStrategy, useClass: HashLocationStrategy},
  //   {provide: LocationStrategy, useClass: PathLocationStrategy},
  // ],
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, config),
    CommonModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
