import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {FileFilter, FileUploader, HookTypeEnum, UploaderHook} from '@uniprank/ngx-file-uploader';

@Component({
  selector: 'app-post-create',
  templateUrl: './post-create-modal.component.html',
  styleUrls: ['./post-create-modal.component.scss']
})
export class PostCreateModalComponent implements OnInit {

  // user_data = {};
  @Input() public user_data: any;
  @ViewChild('inputfile') inputfile: ElementRef;

  public onClose: Subject<boolean>;
  post: any = {
    content: '',
    privacy: 'public',
  }

  public uploader: FileUploader;
  private _subs: Subscription;
  private files: any = [];

  constructor(
    public bsModalRef: BsModalRef,
  ) {

    this.uploader = new FileUploader({
      // url: 'https://api.uniprank.com/imageupload/api/upload',
      removeBySuccess: false,
      autoUpload: false,
      filters: [
        new FileFilter('only:JPG/PNG/GIF', new RegExp('image/jpeg|image/png|image/gif'), 'type')
      ],
    });

    // this.hookName = HookTypeEnum[HookTypeEnum.successUploadFile];

    this.uploader.hook(
      new UploaderHook(
        HookTypeEnum.successUploadFile,
        (response: any, status: any, headers: any) => {
          console.log('Hook', HookTypeEnum.successUploadFile, response, status, headers);
        },
        0
      )
    );

    this._subs = this.uploader.queue$.subscribe(
      async (data: any) => {

        if( data.length > 0) {

          this.files = [];

          for (var i = 0; i < data.length; i++) {

            let file = data[i]._fileElement;

            if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
              this.files.push({
                file: file,
                description: '',
              });
            }
          }
          // this.inputfile.nativeElement.value = '';
        }
        // console.log('files',this.files);
      },
      (error: any) => {
        throw new Error(error);
      }
    );
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  postCreate( formRegister: NgForm ) {
    if (formRegister.valid) {
      this.onConfirm( { post: this.post , data_images: this.files} );
    }
  }

  public onConfirm(data): void {
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  public onError(): void {
    this.onClose.next(false);
  }

  public convertError(value: any): string {
    if (value != null) {
      try {
        return JSON.parse(value).error.text;
      } catch (e) {}
    }
    return '';
  }
}
