import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {NgForm} from '@angular/forms';
import {AuthService} from '../../_auth/auth.service';
import {Router} from '@angular/router';
import { Location } from '@angular/common';
import {AppComponent} from '../../app.component';
import {AuthComponent} from '../../_auth/auth.component';
import {LoginComponent} from '../../_auth/login/login.component';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-modal-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterModalComponent implements OnInit {

  submitted: boolean;
  loading = false;
  errors = [];

  public onClose: Subject<boolean>;

  select_date_start = 1;
  select_date_end = 31;
  select_year_end = new Date().getFullYear();

  select_date_options: any;
  select_year_options: any;
  select_month_options = [
    'มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน','กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'];

  regist: any = {
    bday_date : this.select_date_start,
    bday_month : new Date().getMonth()+1,
    bday_year : new Date().getFullYear(),
    gender: '',
    //mockup
    // firstname: 'm',
    // lastname: 'k',
    // email_or_mobile: '0123456788',
    // password: '123456',
  };

  constructor(
      public bsModalRef: BsModalRef,
      private auth: AuthService,
      // private router: Router,
      // private location: Location,
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.select_year_options = this.getSelectedOptionYear(this.select_year_end,100);
    this.select_date_options = this.getSelectedOptionDate(this.select_date_start, this.select_date_end);
  }

  getSelectedOptionDate(date_start,date_end) {

    var options = [];

    while( date_start <= date_end ){
      options.push(date_start);
      date_start++;
    }
    return options;
  }

  getSelectedOptionYear(end,len) {

    var start = end-len;
    var options = [];

    while( end >= start ){
      options.push(end);
      end--;
    }
    return options;
  }

  register( formRegister: NgForm ) {

      if (formRegister.valid) {

        this.submitted = true;
        this.loading = true;

        this.auth.register(this.regist)
          .subscribe(
            (resp: any) => {
              if (resp && resp.body) {
                if ( resp.body.success) {
                  this.onConfirm(formRegister);
                } else {
                  // this.showToast('error', resp.body.errors);
                  this.onError();
                  this.errors = resp.body.errors;
                }
                this.loading = false;
              }
            },
            error => {
              console.error('error', error.errors);
              this.onError();
              this.errors = error.errors;
              this.loading = false;
              // this.showToast('error', error.errors);
            });
      }
  }

  public onConfirm(form): void {
    this.onClose.next(form);
    this.bsModalRef.hide();
  }

  public onError(): void {
    this.onClose.next(false);
  }
  //
  // socialSignIn(social_platform : string){
  //   if(social_platform !== ''){
  //     this.base.socialSignIn(social_platform);
  //   }
  // }
}
