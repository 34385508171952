import {Injectable, Optional} from '@angular/core';
import {ApiConfig} from './api.config';
import {Observable} from 'rxjs/Observable';
import {HttpClient, HttpRequest, HttpHeaders, HttpEventType, HttpEvent} from '@angular/common/http';
import {Subscription} from 'rxjs/Subscription';
import {LocalStorageService} from 'ngx-webstorage';
// import * as moment from 'moment';
// import {environment} from '../../environments/environment';

@Injectable()
export class Api {

    protected host: string;
    protected prefix: string;

    private authorization: string;
    private timeout: number;
    private mockup: boolean;

    public errorStatusMethods: any;

    constructor(
        private http: HttpClient,
        @Optional() config: ApiConfig,
        private storage: LocalStorageService,
    ) {
        if (config) {
            if (config.host) {
                this.host = config.host;
            }
            if (config.prefix) {
                this.prefix = config.prefix;
            }
            if (config.mockup) {
                this.mockup = config.mockup;
            }
            this.timeout = (config.timeout) ? config.timeout : 20000;
        }
    }

    // private parseServicePath(service_path: string, data: any): string {
    //     if (typeof data === 'object') {
    //         for (const key in data) {
    //             if (key) {
    //                 const patt: string = '{' + key + '}';
    //                 if (service_path.indexOf(patt) > -1) {
    //                     service_path = service_path.replace(patt, data[key]);
    //                 }
    //             }
    //         }
    //         service_path = service_path.replace(/\/?\{[^\?\}]+\?\}/, '');
    //     }
    //
    //     return service_path;
    // }
    //
    private parseQueryString(params_object: any): string {
        let query_string: string;
        let query_array: string[];
        query_array = [];

        if (params_object) {
            for (const key in params_object) {
                if (key) {
                    const value = params_object[key];
                    query_array.push(key + '=' + value);
                }
            }

            if (query_array && query_array.length > 0) {
                query_string = '?' + query_array.join('&');
            }
        }

        return (query_string) ? query_string : '';
    }

    private generateMessageId(): string {
        let messageId: string;
        messageId = '1234567890';

        return messageId;
    }

    public setAuthorization(token: string): void {
        this.authorization = token;
    }

    public getAuthorization(): string {
        if( !this.authorization ){
            this.setAuthorization( this.storage.retrieve('login_token') );
        }
        return 'Bearer ' + this.authorization;
    }

    public getHeaders(): HttpHeaders {
        let headers: HttpHeaders;
        headers = new HttpHeaders()
            .set('Authorization', this.getAuthorization())
            .set('Content-Type', 'application/json')
        ;

        // headers.append('Access-Control-Allow-Headers', 'Content-Type');
        // headers.append('Access-Control-Allow-Methods', 'GET');
        // headers.append('Access-Control-Allow-Origin', '*');

        return headers;
    }

    public getHeaderFile(): HttpHeaders {
        let headers: HttpHeaders;
        headers = new HttpHeaders()
            .set('Authorization', this.getAuthorization())
            // .set('Content-Type', 'Access-Control-Allow-Headers');

        // headers.append('Access-Control-Allow-Headers', 'Content-Type');
        // headers.append('application/x-www-form-urlencoded', 'Content-Type');
        // headers.append('multipart/form-data;boundary='+Math.random(), 'Content-Type');
        headers.append('Access-Control-Allow-Origin', '*');

        return headers;
    }
    //
    // public buildPostOrPutData(model: any, put_or_post_data: string[]): any {
    //     let data: any;
    //     data = {
    //         id: model.id
    //     };
    //     for (let i = 0; i < put_or_post_data.length; i++) {
    //         let key: string;
    //         let value: any;
    //         key = put_or_post_data[i];
    //         value = (<any>model)[key];
    //         (<any>data)[key] = value;
    //     }
    //     return data;
    // }

    public request(service_path: string, method?: string, params?: any, request_data?: any, key_name?: string): Observable<Object> {
        const observable: Observable<Object> = new Observable<Object>(subscriber => {
            let host: string;
            let prefix: string;
            let url: string;
            let headers: HttpHeaders;
            let messageId: string;
            let sendDateTime: string;
            let query_string: string;

            host            = (this.host) ? this.host : '';
            prefix          = (this.prefix) ? this.prefix : '';
            url             = host + prefix + service_path;
            headers         = this.getHeaders();
            // messageId       = this.generateMessageId();
            // sendDateTime    = moment().toISOString();
            query_string    = this.parseQueryString(params);

            // console.log('== API::Request [' + url + '] ==');

            let body: any;
            body = {};

            if (key_name) {
                body[key_name] = request_data;
                /*if (request_data && request_data.id) {
                    let id: string;
                    id = request_data.id;
                    body['id'] = id;
                    delete request_data.id;
                }*/
            } else {
                body = request_data;
            }

          let options: any = {
                headers: headers,
                // responseType: "blob",
                reportProgress: true,
                observe: "events",
            };

            let request: Observable<Object>;
            if (method && method.toLowerCase() === 'post') {
                request = this.http.post(url + query_string, body, options);
            } else if (method && method.toLowerCase() === 'patch') {
              request = this.http.patch(url + query_string, body, options);
            } else if (method && method.toLowerCase() === 'put') {
                request = this.http.put(url + query_string, body, options);
            } else if (method && method.toLowerCase() === 'delete') {
                request = this.http.delete(url + query_string, options);
            } else {
                request = this.http.get(url + query_string, options);
            }


            try {
                let sub: Subscription;
                sub = request.subscribe((event: HttpEvent<any>) => {

                  switch (event.type) {
                    case HttpEventType.Sent:
                      // console.log('Request sent!');
                      subscriber.next(event);
                      break;
                    case HttpEventType.ResponseHeader:
                      // console.log('Response header received!');
                      subscriber.next(event);
                      break;
                    case HttpEventType.DownloadProgress:
                      subscriber.next(event);
                      // const kbLoaded = Math.round(event.loaded / 1024);
                      // console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
                      break;
                    case HttpEventType.Response:
                      // console.log('😺 Done!', event.body);
                      subscriber.next(event);
                  }
                  // subscriber.next(event);

                }, error => {
                    if (error && error.status && this.errorStatusMethods && this.errorStatusMethods[error.status]) {
                        this.errorStatusMethods[error.status](error);
                        subscriber.error();
                    } else {
                        subscriber.error(error.error);
                    }
                }, () => {
                    subscriber.complete();
                });

                // this.start_timeout(sub);
            } catch (e) {
                subscriber.error(e);
            }
        });

        return observable;
    }

    public requestFile(service_path: string, method?: string, form_data?: any): Observable<Object> {
        const observable: Observable<Object> = new Observable<Object>(subscriber => {
            let host: string;
            let prefix: string;
            let url: string;
            let headers: HttpHeaders;

            host            = (this.host) ? this.host : '';
            prefix          = (this.prefix) ? this.prefix : '';
            url             = host + prefix + service_path;
            headers         = this.getHeaderFile();

            console.log('== API::Request File [' + url + '] ==');

          let options: any = {
            headers: headers,
            // responseType: "blob",
            reportProgress: true,
            observe: "events",
          };

            let request: Observable<Object>;
            if (method && method.toLowerCase() === 'post') {
                request = this.http.post(url , form_data, options);
            } else if (method && method.toLowerCase() === 'patch') {
                request = this.http.patch(url, form_data, options);
            } else if (method && method.toLowerCase() === 'put') {
                request = this.http.put(url, form_data, options);
            } else {
                request = this.http.get(url, options);
            }

            try {
                let sub: Subscription;
                sub = request.subscribe((event: HttpEvent<any>) => {
                  switch (event.type) {
                    case HttpEventType.Sent:
                      // console.log('Request sent!');
                      subscriber.next(event);
                      break;
                    case HttpEventType.ResponseHeader:
                      // console.log('Response header received!');
                      subscriber.next(event);
                      break;
                    case HttpEventType.DownloadProgress:
                      subscriber.next(event);
                      // const kbLoaded = Math.round(event.loaded / 1024);
                      // console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
                      break;
                    case HttpEventType.Response:
                      // console.log('😺 Done!', event.body);
                      subscriber.next(event);
                  }
                    // subscriber.next(response);
                }, error => {
                    if (error && error.status && this.errorStatusMethods && this.errorStatusMethods[error.status]) {
                        this.errorStatusMethods[error.status](error);
                        subscriber.error();
                    } else {
                        subscriber.error(error.error);
                    }
                }, () => {
                    subscriber.complete();
                });

                // this.start_timeout(sub);
            } catch (e) {
                subscriber.error(e);
            }
        });

        return observable;
    }

    public uploadFiles(service_path: string, method?: string, uploader?: any) {

        // const observable: Observable<Object> = new Observable<Object>(subscriber => {
        const promise =
            new Promise((resolve, reject) => {
                // Do some promise stuff

                let host: string;
                let prefix: string;
                let url: string;

                host            = (this.host) ? this.host : '';
                prefix          = (this.prefix) ? this.prefix : '';
                url             = host + prefix + service_path;

                uploader.onBeforeUploadItem = (item) => {
                    item.withCredentials = false;
                    item.url = url;
                    item.authTokenHeader = "Authorization";
                    item.method = method;
                    item.uploader.authToken = this.getAuthorization();
                };
                // uploader.additionalParameter = {
                //     comments: 'sdfsfsdfsdfsdfsdf',
                // };

                // console.log('uploader',uploader);
                const image_libraries = [];
                if( uploader.queue && uploader.queue[0] ) {

                    uploader.uploadAll();
                    uploader.onCompleteItem = (fileItem, response, status, headers) => {
                        if (status === 200) {

                          var response_json = JSON.parse(response);
                          image_libraries.push( response_json.image_library );
                          // response_json.push(JSON.parse(response));
                          if( !uploader.getNotUploadedItems().length ) {
                            response_json.image_libraries = image_libraries;
                            resolve(response_json);
                          }
                        }else{
                            reject();
                        }
                    }
                }
            });
        // });

        return promise;
        // return observable;
    }

  public downloadPdf(service_path: string, method?: string, params?: any, request_data?: any) {

    // const observable: Observable<Object> = new Observable<Object>(subscriber => {
    const promise =
      new Promise((resolve, reject) => {
        // Do some promise stuff

        let host: string;
        let prefix: string;
        let url: string;
        let headers: HttpHeaders;
        let query_string: string;

        host            = (this.host) ? this.host : '';
        prefix          = (this.prefix) ? this.prefix : '';
        url             = host + prefix + service_path;
        query_string    = this.parseQueryString(params);

        headers         = this.getHeaderFile();
        headers = headers.set('Accept', 'application/pdf');

        let options: any;
        options = {
          headers: headers,
          responseType: 'blob',
        };

        let request: Observable<Object>;
        if (method && method.toLowerCase() === 'post') {
          request = this.http.post(url + query_string, request_data, options);
        } else if (method && method.toLowerCase() === 'patch') {
          request = this.http.patch(url + query_string, request_data, options);
        } else if (method && method.toLowerCase() === 'put') {
          request = this.http.put(url + query_string, request_data, options);
        } else if (method && method.toLowerCase() === 'delete') {
          request = this.http.delete(url + query_string, options);
        } else {
          request = this.http.get(url + query_string, options);
        }

        request.subscribe(
          (data: any)=>{
            var blob = new Blob([data], {type: 'application/pdf'});
            resolve({
              blob: blob
            });
          },
          err=>{
            // console.error(err);
            reject();
          }
        );

      });
    // });

    return promise;
    // return observable;
  }
}
