import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../../_service/user.service';
import {PostService} from '../../../_service/post.service';

@Component({
  selector: 'app-post-privacy',
  templateUrl: './post-privacy-modal.component.html',
  styleUrls: ['./post-privacy-modal.component.scss']
})
export class PostPrivacyModalComponent implements OnInit {

  public onClose: Subject<any>;
  @Input() public user_data: any;
  @Input() public post_data: any;
  private privacy_update = '';

  privacies = [
    {
      icon : 'globe-asia',
      privacy : 'public',
    },
    {
      icon : 'user-friends',
      privacy : 'friend',
    },
    {
      icon : 'lock',
      privacy : 'private',
    },
  ];
  constructor(
    private _formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private post_service: PostService,
  ) {
  }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.privacy_update = this.post_data.status_privacy;
  }

  updatePhotoAndClose(item){
    this.privacy_update = item.privacy;
    this.post_data.privacy_update = item.privacy;
    this.onClose.next( this.post_data );
    this.bsModalRef.hide();
  }
}
