import { Component } from '@angular/core';
import {faFacebook, faLine} from '@fortawesome/free-brands-svg-icons';
import {
  faCaretDown,
  faChevronDown,
  faInbox,
  faMailBulk,
  faPhone,
  faPlusCircle,
  faEnvelope,
  faBell,
  faShareAlt,
  faUserFriends,
  faUser,
  faGlobeAsia,
  faBars,
  faCamera,
  faCommentDots,
  faEllipsisH,
  faFileUpload,
  faFileImage,
  faTrash,
  faTimesCircle, faLock, faUserPlus, faArrowRight, faCheck, faUserCheck, faUserTimes
} from '@fortawesome/free-solid-svg-icons';
// import {faBell, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
// library.add(fas);
// library.add(far);

import {
  ToastrService,
  GlobalConfig,
} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

// import { SocialAuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
// import {Api} from './_api/api';
// import {AuthService} from './_auth/auth.service';

@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  template: '<router-outlet></router-outlet>' +
    '<ngx-loading-bar color="#f7941d" height="4px"></ngx-loading-bar>',
})
export class AppComponent {

  title = 'AeraLife';
  year = new Date().getFullYear();
  options: GlobalConfig;

  constructor(
    private library: FaIconLibrary,
    private toastr: ToastrService,
    private translate: TranslateService,
  ){
    // library.add(fas,far);
    library.addIcons(faLine, faPhone, faMailBulk, faFacebook, faEnvelope, faPlusCircle, faInbox, faBell, faCaretDown, faChevronDown, faShareAlt, faUserFriends, faUser, faGlobeAsia, faBars, faCamera, faCommentDots, faEllipsisH,faFileUpload, faFileImage, faTrash, faTimesCircle,faLock, faUserPlus, faArrowRight, faCheck, faUserCheck, faUserTimes);

    this.options = toastr.toastrConfig;
    this.options.closeButton = true;
    this.options.progressBar = true;
  }

  public showToast(type: string, body: any , title?: string ) {

    title = 'ผิดพลาด!';
    if( type ){
      if( type=='success' ) {
        title = 'สำเร็จ';
      }
      else if( type=='error' ) {
        type = 'danger';
      }
    }

    var error_inputs = [];
    if( typeof body !== 'string' && ( body instanceof Array || typeof body === 'object') ){
      for(var key in body){

        var field: string = body[key].field?body[key].field:'';
        var message: string = body[key].message;
        if( field == 'unauthenticated'){
          error_inputs = [{
            type : 'error',
            title : title,
            body : message,
          }];
          break;
        }else{
          error_inputs.push({
            type : 'error',
            title : title,
            body : message,
          });
        }
      }
    }else{
      error_inputs = [{
        type : type,
        title : title,
        body : body,
      }];
    }

    for(var key in error_inputs) {
      //     const toast: Toast = {
      //         type: error_inputs[key].type,
      //         title: error_inputs[key].title,
      //         body: error_inputs[key].body,
      //         timeout: this.timeout,
      //         showCloseButton: this.isCloseButton,
      //         bodyOutputType: BodyOutputType.TrustedHtml,
      //     };
      //     this.toasterService.popAsync(toast);
      this.toastr.show(
        this.translate.instant( 'message.' + error_inputs[key].body ),
        error_inputs[key].title,
        this.options,
        this.options.iconClasses[error_inputs[key].type]);

      // if( error_inputs[key].type=='success' ){
      //   this.toastr.success(
      //     error_inputs[key].body,
      //     error_inputs[key].title,
      //     this.options,
      //     this.options.iconClasses[error_inputs[key].type],
      //   );
      // }else{
      //   this.toastr.error(
      //     error_inputs[key].body,
      //     error_inputs[key].title,
      //     this.options,
      //     this.options.iconClasses[error_inputs[key].type],
      //   );
      // }
    }
  }
}
