import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {faCommentAlt, faThumbsUp} from '@fortawesome/free-regular-svg-icons';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PostService} from '../../../_service/post.service';
import {PostDataService} from '../../../_service/post-data.service';
import {AuthService} from '../../../_auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-photo-view',
  templateUrl: './photo-view-modal.component.html',
  styleUrls: ['./photo-view-modal.component.scss']
})
export class PhotoViewModalComponent implements OnInit {

  @Input() public user: any;
  @Input() public datas: any;
  @Input() public data_index: number;
  @Input() public fid: number;

  post_id: number = null;
  data: any = {};
  post: any = {};
  image_loading: boolean = false;
  total: number;
  user_data: any = {};
  comment_loading: boolean = false;
  show_reply_comment: any = [];
  data_edit_status: boolean = false;
  public onClose: Subject<any>;
  update_loading: boolean = false;

  farThumbsUp = faThumbsUp;
  farCommentAlt = faCommentAlt;

  constructor(
    public bsModalRef: BsModalRef,
    private postService: PostService,
    private postDataService: PostDataService,
    private auth: AuthService,
    private translate: TranslateService,
    // private app: AppComponent,
    private location: Location,
    // @Optional()  private app: AppComponent,
  ) {
    if( this.datas && this.datas[this.data_index] && this.datas[this.data_index].image_url ){
      this.data.image_url = this.datas[this.data_index].image_url;
    }

    if( this.auth.is_logged ){
      this.user_data = this.auth.login_data;
    }
  }

  ngOnInit(): void {
    this.onClose = new Subject();

    if( this.datas.length == 1 ){
      this.data = this.datas[0];
      this.data.image_last = true;
      this.total = 1;

      this.getData();
    }else{
      this.listData();
    }

  }

  public getData(){
    this.image_loading = true;
    this.postDataService.get(this.datas[this.data_index].id).subscribe(
      (resp: any) => {
        if (resp && resp.body ) {
          if( resp.body.post_data ) {
            // this.datas = [resp.body.post_data];
            // this.data = resp.body.post_data;
            this.data.my_feel = resp.body.post_data.my_feel;
            this.data.timeago_text = resp.body.post_data.timeago_text;
            this.total = resp.body.total;
            this.data.image_last = true;
            this.user = resp.body.post_data.post.user;
            // console.log('this.data',this.data);
          }else{
          }
          this.image_loading = false;
        }
      },
      error => {
        this.image_loading = false;
      }
    );
  }

  public listData(){

    this.image_loading = true;
    this.postDataService.list(this.datas[this.data_index].post_id).subscribe(
      (resp: any) => {
        if (resp && resp.body ) {
          if( resp.body.post_datas ) {
            this.datas = resp.body.post_datas;
            this.data = this.datas[this.data_index];
            this.total = resp.body.total;
            this.post = this.datas[this.data_index];
            this.user = this.datas[this.data_index].post.user;
          }
          this.image_loading = false;
        }
      },
      error => {
        this.image_loading = false;
      }
    );
  }

  prevImage(){

    if( this.data_index>0 ){
      this.data_index--;
      this.data = this.datas[this.data_index];
      this.setUrl();

      window.scroll(0,0);
    }
  }

  nextImage(){

    if( (this.data_index+1) < this.total ) {

      this.data_index++;

      if( this.datas[this.data_index] ){
        this.data = this.datas[this.data_index];
        this.setUrl();

        window.scroll(0,0);
      }
    }
  }

  setUrl(){
    if( this.fid > 0){
      this.location.replaceState('/photo/' + this.data.id + '?fid=' + this.fid);
      // history.pushState(null, null, '/photo/' + this.data.id + '?fid=' + this.fid);
    }else{
      this.location.replaceState('/photo/' + this.data.id);
      // history.pushState(null, null, '/photo/' + this.data.id);
    }
  }

  public close(): void {

    if( this.datas.length == 1 ) {

      this.post.content = this.data.descrption;
      this.onClose.next( { data: this.data } );

    }
    else if( this.datas.length > 1 ) {
      this.onClose.next( { datas: this.datas } );
    }else{
      this.onClose.next(false);
    }
    this.bsModalRef.hide();
  }

  public setFeel(item,feel){

    if( !item.my_feel ){
      item.my_feel = {};
    }

    if( !item.my_feel.type || (item.my_feel.type && item.my_feel.type == 'none')){
      item.my_feel.type = feel;
    }else{
      item.my_feel.type = 'none';
    }

    this.postDataService.changeFeelType(item)
      .subscribe(
        (resp: any) => {
          if (resp && resp.body && resp.body.my_feel ) {
            item.my_feel = resp.body.my_feel;
            if( resp.body.my_feel.type != 'none' ){
              item.feels_count++;
            }else{
              item.feels_count--;
            }
          }else{
            item.my_feel.type = 'none';
          }
        },
        error => {
          item.my_feel.type = 'none';
        });
  }

  postComment(form: NgForm , item): void {

    // this.submitted = true;
    if (form.valid) {

      this.postDataService.createComment( item , form.value.comment )
        .subscribe( (resp: any): void => {
          if (resp && resp.body.success) {
            if( item.comments ) {
              item.comments.unshift(
                resp.body.comment_last
              );
            }else{
              item.comments = [
                resp.body.comment_last
              ];
            }
            item.comments_count++;
            form.resetForm();
          }
        }, error => {
          console.log('create comment failed',error);
        });
    } else {
      // this.swal.danger(AUTH_INVALID);
    }
  }

  editComment(item){

    item.show_edit = true;
    item.comment_update = item.comment;
  }

  editCommentCancel(item){
    item.show_edit = false;
    item.comment_update = '';
  }

  commentConfirmDelete(comments,item: any) {

    if ( window.confirm( this.translate.instant( 'post.warn_del_comment') ) ){

      this.comment_loading = true;
      this.postDataService.deleteComment(item.id)
        .subscribe(
          (resp: any) => {
            if( resp.body.success ){
              var index = comments.indexOf(item);
              comments.splice(index, 1);
            }else{
            }
            this.comment_loading = false;
          },
          error => {
            this.comment_loading = false;
          }
        );
    }
  }

  updateComment(item){

    item.updating = true;
    this.postDataService.updateComment(item)
      .subscribe(
        (resp: any) => {
          if (resp && resp.body.success) {

            item.comment = resp.body.post_data_comment.comment;
            item.comment_update = '';
            item.show_edit = false;
          } else {
            console.error('error', resp.body.errors);
          }
          item.updating = false;
        },
        error => {
          item.updating = false;
          console.error('error', error.errors);
        });
  }

  public setFeelComment(item,feel){

    if( !item.my_feel ){
      item.my_feel = {};
    }

    if( !item.my_feel.type || (item.my_feel.type && item.my_feel.type == 'none')){
      item.my_feel.type = feel;
    }else{
      item.my_feel.type = 'none';
    }

    this.postDataService.changeCommentFeelType(item)
      .subscribe(
        (resp: any) => {
          if (resp && resp.body && resp.body.my_feel ) {
            item.my_feel = resp.body.my_feel;
            if( resp.body.my_feel.type != 'none' ){
              item.feels_count++;
            }else{
              item.feels_count--;
            }
          }else{
            item.my_feel.type = 'none';
          }
        },
        error => {
          item.my_feel.type = 'none';
        });
  }

  addReplyCommentForm(comment_id){
    this.show_reply_comment[comment_id] = true;
  }

  postCommentReply(form: NgForm , item_comment, post): void {

    // this.submitted = true;
    if (form.valid) {

      this.postDataService.createCommentReply( item_comment , form.value.comment_reply )
        .subscribe( (resp: any): void => {
          if (resp && resp.body.success) {
            if( item_comment.comment_replies ) {

              // form.value.comment = '';
              form.resetForm();
              this.show_reply_comment[item_comment.id] = false;

              item_comment.comment_replies.unshift(
                resp.body.comment_reply_last
              );
              post.comments_count++;

            }else{
              item_comment.comment_replies = [
                resp.body.comment_reply_last
              ];
            }
          }
        }, error => {
          console.log('create comment failed',error);
        });
    } else {
      // this.swal.danger(AUTH_INVALID);
    }
  }

  imageConfirmDelete(item){

    if ( window.confirm( this.translate.instant( 'post.warn_del_post_data') ) ){

      this.postDataService.delete(item.id)
        .subscribe(
          (resp: any) => {
            if( resp.body.success ){

              var index = this.datas.indexOf(item);

              if( this.datas[index+1] ) {

                this.datas.splice(index, 1);

                this.total = this.datas.length;
                this.data = this.datas[index];

                console.log(this.datas);
              }
              else if( this.datas[index-1] ) {

                this.datas.splice(index, 1);

                this.total = this.datas.length;
                this.data_index = index-1;
                this.data = this.datas[this.data_index];

                console.log(this.datas);
              }
              else{
                this.onClose.next(false);
              }
            }
          },
          error => {
          }
        );
    }
  }

  dataEdit(){
    this.data_edit_status = true;
    this.data.description_update = this.data.description;
  }

  dataUpdate( form: NgForm ) {

    if (form.valid) {

      this.update_loading = true;

      this.postService.updateDescription(this.data)
        .subscribe(
          (resp: any) => {
            if (resp && resp.body.success) {
              this.data_edit_status = false;
              this.data.description_update = null;
              this.data.description = resp.body.post_data.description;
            } else {
              console.error('error', resp.body.errors);
            }
            this.update_loading = false;
          },
          error => {
            this.update_loading = false;
            console.error('error', error.errors);
          });
    }
  }

  dataEditCancel(){
    this.data_edit_status = false;
    this.data.description_update = null;
  }

}
