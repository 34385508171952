import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ApiConfig} from './api.config';
import {Api} from './api';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    exports: [
        //
    ],
    declarations: [
        //
    ],
    providers: [
        Api
    ]
})
export class ApiModule {

    public static forRoot(config?: any): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [
                {
                    provide: ApiConfig,
                    useValue: config.apiConfig
                }
            ]
        };
    }

}
